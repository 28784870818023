import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_TRANSACTIONS_REQUEST = 'app/ManageTransactionsPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/ManageTransactionsPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/ManageTransactionsPage/FETCH_TRANSACTIONS_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageTransactionsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
};

const resultIds = data => data.data.map(l => l.id);

const mapTransactions = (transactions, relationships) => {

  return transactions.map(transaction => ({
    ...transaction,
    customer: relationships.find(e => e.type === 'user' && e.id.uuid === transaction.relationships.customer.data.id.uuid),
    listing: relationships.find(e => e.type === 'listing' && e.id.uuid === transaction.relationships.listing.data.id.uuid),
  }));
}

/*

 "relationships": {
    "customer": {
      "data": {
        "id": {
          "_sdkType": "UUID",
          "uuid": "6577fec4-7aab-4979-b507-1eaa880760c0"
        },
        "type": "user"
      }
    },
    "listing": {
      "data": {
        "id": {
          "_sdkType": "UUID",
          "uuid": "6573a53c-0f46-49e5-befe-c19f2b5db437"
        },
        "type": "listing"
      }
    }
  }*/

const manageTransactionsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTIONS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        transactions: mapTransactions(payload.data.data, payload.data.included),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_TRANSACTIONS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    default:
      return state;
  }
};

export default manageTransactionsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} transactionIds listing IDs to select from the store
 */
export const getOwnTransactionsById = (state) => {
  const { transactions } = state.ManageTransactionsPage;
  return transactions;
};

// ================ Action creators ================ //


export const queryTransactionsRequest = queryParams => ({
  type: FETCH_TRANSACTIONS_REQUEST,
  payload: { queryParams },
});

export const queryTransactionsSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { data: response.data },
});

export const queryTransactionsError = e => ({
  type: FETCH_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnTransactions = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryTransactionsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.transactions
    .query(params)
    .then(response => {
      dispatch(queryTransactionsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryTransactionsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  const only = queryParams.only || 'sale';

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryOwnTransactions({
    ...queryParams,
    page,
    only,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock', 'customer', 'listing'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
