import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { PushNotifications } from '@capacitor/push-notifications';
import isUndefined from 'lodash/isUndefined';
import { isMobileApp, isAppleDevice } from '../../util/mobileApp';
import { ToastContainer, toast } from 'react-toastify';
import css from './PushNotificationListener.module.css';
import classNames from 'classnames';

const registerNotifications = async (localForage) => {    
    let permStatus = await PushNotifications.checkPermissions();
    console.log('PushNotifications.checkPermissions()', permStatus);
    
    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }    

    if (permStatus.receive !== 'granted') {
        console.log('user rejected push notifications');
        localForage.setItem('notificationPermission', false);
        return false;
    }
    console.log('permStatus', JSON.stringify(permStatus));

    await PushNotifications.register();
    localForage.setItem('notificationPermission', true);
    return true;
}

// https://capacitorjs.com/docs/guides/deep-links
const PushNotificationListener = () => {
    let history = useHistory();

    console.log('Push notification listener');
    useEffect(async () => {
        if (isMobileApp()) {
            const localForage = require('localforage');
            console.log('Initializing Push Notifications');

            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting

            const canRegister = await registerNotifications(localForage);

            console.log('canRegister', canRegister);

            if (canRegister) {
                console.log('Registration done, creating listeners');
                // On success, we should be able to receive notifications
                PushNotifications.addListener('registration',
                    (token) => {
                        console.log('Push registration success, token: ' + token.value);
                        localForage.setItem('notificationRegistrationToken', token.value);
                    }
                );

                // Some issue with our setup and push will not work
                PushNotifications.addListener('registrationError',
                    (error) => {
                        console.log('Error on registration: ' + JSON.stringify(error));
                        alert('Error on registration: ' + JSON.stringify(error));
                    }
                );
                const classes = classNames('', { [css.iOSNotchPadding]: isAppleDevice() });

                // Show us the notification payload if the app is open on our device
                PushNotifications.addListener('pushNotificationReceived',
                    (notification) => {

                        const Msg = ({ closeToast }) => (<div className={classes}>
                            <h3>{notification.title}</h3>
                            {!isUndefined(notification.data.url) ? <button
                                className={'buttonDefault'}
                                onClick={() => {
                                    history.push(notification.data.url);
                                }}>View</button> : null}
                        </div>
                        )
                        toast.info(<Msg />, {
                            position: "top-center",
                            autoClose: 8000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                );

                // Method called when tapping on a notification
                PushNotifications.addListener('pushNotificationActionPerformed',
                    (notification) => {
                        console.log('Push action performed: ' + JSON.stringify(notification.notification.data));
                        // console.log('Push action performed url: ' + JSON.stringify(notification.notification.data.url));
                        if (!isUndefined(notification.notification.data.url)) {
                            console.log('has url');
                            history.push(notification.notification.data.url);
                        } else {
                            console.log('url undefined');
                        }
                    }
                );
            }
        };
    }, []);


    // test code

    // const Msg = ({ closeToast }) => (<div>
    //     <h3>Quentin d has sent you a new message.</h3>
    //     <button
    //         className={'buttonDefault'}
    //         onClick={() => {
    //             history.push('/');
    //         }}>View</button>
    // </div>
    // )
    // setTimeout(() => {
    //     toast.info(<Msg />, {
    //         position: "top-center",
    //         // autoClose: 8000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //     });
    // }, 3000);

    return <div>
        <ToastContainer
            position="top-center"
            autoClose={false}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
        />
    </div>;
}


export default PushNotificationListener;
