import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from '@capacitor/app';

// https://capacitorjs.com/docs/guides/deep-links
const AppUrlListener = () => {
    let history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (data) => {
            console.log('app Url Opened', data);
            const slug = data.url.split('.app').pop();
            if (slug) {
                console.log('slug', slug);
                history.push(slug.replace(PROCESS.env.REACT_APP_DEEPLINK_DOMAIN, ''));
            }
        });
    }, []);

    return null;
};

export default AppUrlListener;