export const isMobileApp = () => {
    if (typeof window === 'undefined') {
        return false;
    }
    // Add a script to set the mobile app in the index.html
    return window.isMobileApp === true;
}

export const isAppleDevice = () => {
    return  /iPhone|iPod|iPad/.test(navigator.platform);
}